// import { Link } from 'gatsby'
import { Link } from 'gatsby'
import React from 'react'
import MentorshipPreview from './mentorship-preview'

function MentorshipPreviewGrid(props) {
  return (
    <div>
      {props.title && <h2>{props.title}</h2>}
      <ul className="preview-list">
        {props.nodes &&
          props.nodes.map(node => (
            <li key={node.id}>
              <MentorshipPreview {...node} isInList />
            </li>
          ))}
      </ul>
    </div>
  )
}

MentorshipPreviewGrid.defaultProps = {
  title: '',
  nodes: [],
}

export default MentorshipPreviewGrid
