import React from 'react'
import { graphql } from 'gatsby'
import {
  mapEdgesToNodes,
  filterOutDocsWithoutSlugs,
  filterOutDocsPublishedInTheFuture,
} from '../lib/helpers'
import MentorshipPreviewList from '../components/mentorship-preview-list'
import GraphQLErrorList from '../components/graphql-error-list'
import SEO from '../components/seo'
import Layout from '../containers/content'
import ListIntro from '../components/list-intro'
import ContentContainer from '../containers/content'

export const query = graphql`
  query MentorshipIndexPageQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
      description
      keywords
      openGraph {
        image {
          ...SanityImage
        }
      }
    }
    mentorshipsIntro: sanityMentorshipsIntro(_id: { regex: "/(drafts.|)mentorshipsIntro/" }) {
      _rawIntroText(resolveReferences: { maxDepth: 5 })
    }
    mentorships: allSanityMentorship(
      sort: { fields: [endDate], order: DESC }
      filter: { slug: { current: { ne: null } }, isVisible: { eq: true } }
    ) {
      edges {
        node {
          id
          title
          artist
          location
          startDate
          endDate
          tags {
            _id
            title
          }
          previewImageSize {
            widthDesktop
          }
          mainImage {
            ...SanityImage
            alt
          }
          _rawExcerpt
          slug {
            current
          }
          isVisible
          _rawFormattedTitle
        }
      }
    }
  }
`

const MentorshipIndexPage = props => {
  const { data, errors } = props
  const navIntroText = (data.navMenu && data.navMenu.introText) || ''
  const mentorshipsIntro = data.mentorshipsIntro
  const menuItems = data.navMenu && (data.navMenu.items || [])
  const menuFooterItems = data.navMenu && (data.navMenu.footerItems || [])

  if (errors) {
    return (
      <ContentContainer>
        <GraphQLErrorList errors={errors} />
      </ContentContainer>
    )
  }

  const site = (data || {}).site
  const ogImage = site.openGraph.image
  const mentorshipNodes = (data || {}).mentorships
    ? mapEdgesToNodes(data.mentorships)
        .filter(filterOutDocsWithoutSlugs)
        .filter(filterOutDocsPublishedInTheFuture)
    : []

  if (!site) {
    console.warn(
      'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
    )
  }

  return (
    <ContentContainer>
      <SEO
        title="Balkan...Mentorship"
        description={site.description || ''}
        keywords={site.keywords || []}
        image={ogImage}
      />
      <div className="page-container is-mentorship-overview">
        <h1 hidden>{site.title}</h1>
        {mentorshipsIntro && <ListIntro {...mentorshipsIntro} />}
        <div>{mentorshipNodes && <MentorshipPreviewList nodes={mentorshipNodes} />}</div>
      </div>
    </ContentContainer>
  )
}

export default MentorshipIndexPage
